@mobile: ~'only screen and (max-width: 576px)';
.register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    // margin-bottom: 0.5rem;

    .header {
        text-align: center;
        h2 {
            font-weight: 500;
        }

        h3 {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .submit-form {
        & > .form {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            max-width: 100%;

            div {
                max-width: 18.5rem;
            }

            input {
                width: -moz-available;
                width: -webkit-fill-available;
                width: fill-available;
            }

            //hide up/down arrow on input
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type='number'] {
                -moz-appearance: textfield;
            }

            label {
                font-weight: 500;
                display: inline-block;
                align-items: center;
                justify-content: center;
            }

            .left-label {
                margin-right: 0;
            }

            .optional {
                font-weight: 700;
                display: inline-block;
                margin: 0;
                font-size: x-small;
                color: #909090;
                align-items: center;
                justify-content: center;
            }
        }

        .actions {
            display: flex;
            gap: 1rem;
            flex-direction: row;
            justify-content: space-evenly;

            button {
                margin-top: 1em;
                width: 6em;
            }
        }
    }
}
@media @mobile {
    .register {
        .submit-form {
            width: 70%;

            & > .form {
                flex-direction: column;
                gap: 1rem;

                div {
                    max-width: 100%;
                }

                label {
                    display: inherit;
                    width: 100%;
                }
            }

            .actions {
                width: 100%;
                justify-content: space-between;
                gap: 0;

                button {
                    width: 5.919em;
                }
            }
        }
    }
}
