.login {
    display: flex;
    flex-direction: column;
    flex-shrink: 2;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .header {
        margin: 0px;
        text-align: center;
    }

    .notification-component,
    .notification[kind='error'],
    .notification[kind='success'] {
        height: unset;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }

    .footer {
        margin-top: 2rem;
        font-size: 1.3rem;
        text-align: center;

        div:first-child {
            margin-bottom: 1rem;
        }
    }

    .redirect {
        cursor: pointer;
        text-decoration: underline;
        color: #3878c7;
    }
    //hide up/down arrow on input
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
}
