@import './sizes.less';

// TEKST

.tekst-s {
    font-size: @tekst-s;
}
.tekst-m {
    font-size: @tekst-m;
}
.tekst-l {
    font-size: @tekst-l;
}
.tekst-xl {
    font-size: @tekst-xl;
}

.bold {
    font-weight: bold;
}

.tekst-s,
.tekst-m,
.tekst-l,
.tekst-xl {
    font-family: sans-serif;

    &.serif {
        font-family: serif;
    }
}

.underline-pad {
    margin: 0 -4px;
    padding: 0 4px;
}

// BOOTSTRAP

.col-fix {
    padding: 0 @panel-padding-s / 2;
}

.row-fix {
    margin: 0 -(@panel-padding-s / 2);
}

.container-fix {
    padding: 0;
}
