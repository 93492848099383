.print {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    @media print {
        .no-print {
            visibility: hidden;
        }
    }
}
