.lds-default {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-default div {
  width: 6px;
  height: 6px;
  background: #c83541;
  border-radius: 50%;
  animation: 1.2s linear infinite lds-default;
  position: absolute;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.tekst-s {
  font-size: 12px;
}

.tekst-m {
  font-size: 16px;
}

.tekst-l {
  font-size: 24px;
}

.tekst-xl {
  font-size: 34px;
}

.bold {
  font-weight: bold;
}

.tekst-s, .tekst-m, .tekst-l, .tekst-xl {
  font-family: sans-serif;
}

.tekst-s.serif, .tekst-m.serif, .tekst-l.serif, .tekst-xl.serif {
  font-family: serif;
}

.underline-pad {
  margin: 0 -4px;
  padding: 0 4px;
}

.col-fix {
  padding: 0 10px / 2;
}

.row-fix {
  margin: 0 -5px;
}

.container-fix {
  padding: 0;
}

html, body {
  height: 100%;
  background-color: #fbf8f4;
  font-family: Open Sans, sans-serif;
}

@media only screen and (max-width: 750px) {
  html, body {
    overflow-x: auto;
  }
}

#hovedcontainer-wrapper {
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

#hovedcontainer-wrapper #hovedcontainer {
  padding-bottom: 0;
}

#hovedcontainer-wrapper #hovedcontainer #hovedinnhold {
  padding: 0 7.5px;
}

:root {
  --vh: 0vh;
  font-family: Open Sans, Arial, sans-serif;
}

body {
  margin: 0;
}

html {
  background-color: #fbf8f4;
  margin: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.app {
  min-height: 100%;
  height: 100vh;
  place-content: center;
  font-family: Open Sans, Arial, sans-serif;
  font-size: 14px;
  display: flex;
}

.app .form-container {
  max-width: 312px;
  width: 100%;
  flex-direction: column;
  display: flex;
}

.app .form-container button {
  width: 100%;
  margin-top: 5%;
  font-size: 24px;
}

.app .form-container input {
  font-size: 24px;
}

.app .image-container {
  justify-content: center;
  margin-bottom: 1.5em;
  display: flex;
}

.app .image-container img {
  width: 100vw;
  max-width: 312px;
  min-width: 131px;
  min-height: 27px;
}

.app .notification[kind="success"] {
  background-color: #ebf9f5;
  border: 2px solid #59b395;
}

.app .notification[kind="success"] .notificationicon {
  color: #59b395;
}

.app .notification[kind="error"] {
  background-color: #faebeb;
  border: 2px solid #cb333b;
}

.app .notification[kind="error"] .notificationicon {
  color: #cb333b;
}

.register {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.register .header {
  text-align: center;
}

.register .header h2 {
  font-weight: 500;
}

.register .header h3 {
  font-size: 16px;
  font-weight: 500;
}

.register .submit-form > .form {
  max-width: 100%;
  flex-direction: row;
  gap: 2rem;
  display: flex;
}

.register .submit-form > .form div {
  max-width: 18.5rem;
}

.register .submit-form > .form input {
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}

.register .submit-form > .form input::-webkit-outer-spin-button, .register .submit-form > .form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.register .submit-form > .form input[type="number"] {
  -moz-appearance: textfield;
}

.register .submit-form > .form label {
  justify-content: center;
  align-items: center;
  font-weight: 500;
  display: inline-block;
}

.register .submit-form > .form .left-label {
  margin-right: 0;
}

.register .submit-form > .form .optional {
  color: #909090;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: x-small;
  font-weight: 700;
  display: inline-block;
}

.register .submit-form .actions {
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1rem;
  display: flex;
}

.register .submit-form .actions button {
  width: 6em;
  margin-top: 1em;
}

@media only screen and (max-width: 576px) {
  .register .submit-form {
    width: 70%;
  }

  .register .submit-form > .form {
    flex-direction: column;
    gap: 1rem;
  }

  .register .submit-form > .form div {
    max-width: 100%;
  }

  .register .submit-form > .form label {
    display: inherit;
    width: 100%;
  }

  .register .submit-form .actions {
    width: 100%;
    justify-content: space-between;
    gap: 0;
  }

  .register .submit-form .actions button {
    width: 5.919em;
  }
}

.print {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media print {
  .print .no-print {
    visibility: hidden;
  }
}

.login {
  flex-direction: column;
  flex-shrink: 2;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.login .header {
  text-align: center;
  margin: 0;
}

.login .notification-component, .login .notification[kind="error"], .login .notification[kind="success"] {
  height: unset;
  padding-top: .3rem;
  padding-bottom: .3rem;
}

.login .footer {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.3rem;
}

.login .footer div:first-child {
  margin-bottom: 1rem;
}

.login .redirect {
  cursor: pointer;
  color: #3878c7;
  text-decoration: underline;
}

.login input::-webkit-outer-spin-button, .login input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login input[type="number"] {
  -moz-appearance: textfield;
}

.landing-page {
  flex-direction: column;
  flex-shrink: 2;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

/*# sourceMappingURL=index.ed6496c6.css.map */
