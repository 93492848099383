:root {
    --vh: 0vh;
    font-family: 'Open Sans', Arial, sans-serif;
}

body {
    margin: 0px;
}

html {
    margin: 0px;
    background-color: #fbf8f4;
}
// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.app {
    display: flex;
    justify-content: center;
    min-height: 100%;
    font-family: Open Sans, Arial, sans-serif;
    font-size: 14px;
    place-content: center;
    height: 100vh;

    .form-container {
        max-width: 312px;
        display: flex;
        flex-direction: column;
        width: 100%;

        button {
            margin-top: 5%;
            width: 100%;
            font-size: 24px;
        }

        input {
            font-size: 24px;
        }
    }

    .image-container {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5em;

        img {
            width: 100vw;
            max-width: 312px;
            min-width: 131px;
            min-height: 27px;
        }
    }

    .notification[kind='success'] {
        background-color: #ebf9f5;
        border-color: #59b395;
        border: solid 2px #59b395;

        .notificationicon {
            color: #59b395;
        }
    }

    .notification[kind='error'] {
        background-color: #faebeb;
        border-color: #cb333b;
        border: solid 2px #cb333b;

        .notificationicon {
            color: #cb333b;
        }
    }
}
