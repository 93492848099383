@import './less/classes.less';
@import './less/sizes.less';
@import './less/colors.less';
@mobile: ~'only screen and (max-width: 750px)';

html,
body {
    height: 100%;
    background-color: @app-primary;
    font-family: 'Open Sans', sans-serif;
    @media @mobile {
        overflow-x: auto;
    }
}

#hovedcontainer-wrapper {
    -webkit-overflow-scrolling: touch;
    height: 100%;

    #hovedcontainer {
        padding-bottom: 0;

        #hovedinnhold {
            padding: 0 7.5px;
        }
    }
}
